import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseDTO } from '../interfaces/response.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  http = inject(HttpClient)

  public getContacts(
    property: string = "",
    limit: number = 10,
    page: number = 1,
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/contacts/search`,
      body,
      {
        withCredentials: true,
        headers: {
          property,
        },
      },
    );
  }

}
